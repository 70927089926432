/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import Title from "../Title";
import styled from "styled-components";
import { emailStrings } from "./ContactStrings";

const EmailContact = () => (
  <ContactWrapper>
    <Title title="Contact us" />
    <div>
      <form action="https://formspree.io/xzbjpkor" method="POST">
        <FormGroup>
          <Label htmlFor="name">Name</Label>
          <Input type="text" name="name" id="name" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          <Input type="email" name="email" id="email" />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="message">Message</Label>
          <TextArea
            name="message"
            id="message"
            rows="10"
            placeholder={emailStrings.messagePlaceholder}
          />
          <Button type="submit" value={emailStrings.submitText} />
        </FormGroup>
      </form>
    </div>
  </ContactWrapper>
);

const ContactWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  text-align: center;
  font-size: 1.1rem;
  color: #1e2c33;
  padding-bottom: 80px;
  p {
    text-align: left;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 2.5rem;
  margin-top: 0;
  display: block;
  position: relative;
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 0.5rem;
  background-color: #fff;
  font-size: 14px;
  position: absolute;
  cursor: text;
  opacity: 1;
  top: -0.75rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 5px 10px;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  line-height: 1.5;
  border: 1px solid #d7d7d7;
  background: #fff;
`;

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 6px;
  outline: initial !important;
  box-shadow: none !important;
  font-size: 1rem;
  padding: 1rem 1.5rem;
  line-height: 1.5;
  border: 1px solid #d7d7d7;
  background: #fff;
`;

const Button = styled.input`
  margin-top: 2rem;
  float: right;
  text-decoration: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  background-color: var(--primaryColor);
  &:hover {
    background-color: #334b7d;
  }
  padding: 1rem 2rem;
  border-radius: 2rem;
  width: 150px;
  transition: var(--mainTransition);
`;

export default EmailContact;
