import React from "react";
import styled from "styled-components";

const Title = ({ title, subtitle, className }) => (
  <div className={className}>
    <h4>
      <div className="title">{title}</div>
      <div>{subtitle}</div>
    </h4>
  </div>
);

export default styled(Title)`
  font-size: 1.5rem;
  margin-bottom: 0;
  margin-top: 0;

  h4 {
    text-align: center;
    letter-spacing: 4px;
    color: var(--primaryColor);
  }

  .title {
    color: var(--mainBlack);
  }
  span {
    display: block;
  }

  @media (min-width: 576px) {
    font-size: 2.3rem;
    margin: 2rem 1rem;
    span {
      display: inline;
      margin: 0 0.35rem;
    }
  }
`;
