import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import EmailContact from "../components/Contact/EmailContact";
import SEO from "../components/SEO";

// eslint-disable-next-line react/display-name
export default ({ data }) => (
  <Layout>
    <SEO
      title="Contact"
      description="Contact us to talk about how we can help with your next project"
    />
    <StyledHero
      img={data.contactImage.childImageSharp.fluid}
      backgroundColor="0"
    >
      <Banner title="Contact" />
    </StyledHero>
    <ContactWrapper>
      <EmailContact />
    </ContactWrapper>
  </Layout>
);

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  padding: 2rem;
`;

export const getContactImage = graphql`
  query {
    contactImage: file(relativePath: { eq: "new-boat.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
